.main-content {
  background: rgb(82,75,101);
  background: linear-gradient(180deg, #8d6894 0%, #000000 37%, #000000 70%, #000000 100%);
  background-color: #0F131E;
  color: #eaf8ff;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: inherit;
  overflow: hidden inherit;
}

.overflow-hidden {
  overflow: hidden !important;
}

.pe-n {
  pointer-events: none !important;
}