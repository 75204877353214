.pb-gif {
    height: 20px;
    position: absolute; 
}

.claim-button {
    width: 100%;
}

.banner-container {
    padding-top: 13px;
    margin: 0;
    .banner-img{
        border-radius: 20px 20px 0 0;
        max-width:100%;
        max-height:100%;
    }
    .banner-img-over {
        max-width:100%;
        max-height:100%;
    }
}

hr{
    margin: 0;
}

.player-component {
    padding: 2px;
    
    .container-fluid {
        background-color: rgba($color: #000000, $alpha: .05);
        border-radius: 20px;
        padding-bottom: 8px;
    }
}

.player-status {
    text-align: center;
    margin: 0px;
    background-color: rgba($color: #000000, $alpha: 1);
}

.player-np {
    .card {
        overflow: hidden;
        transition: 0.4s;
        padding: 5px;
        border-radius: 4px;
    }
    .border-secondary {
        border-color: #3a4048 !important;
    }
    .border {
        border: 1px solid #eff1f3 !important;
    }
}
.np-icon {
    background: url(https://wydmisc.raidhut.com.br/neil/images/np_icon.png);
    width: 25px;
    height: 25px;
    position: absolute;
}

.player-store {
    padding: 10px;
    .np-price {
        text-align: right;
        width: 100%;
        height: 25px;
        background: rgba($color: #000000, $alpha: .5);
        border-radius: 5px;
        border: 1px solid #eff1f3 !important;
    }
    .store-item {
        text-align: center;
        padding: 10px;
        background: rgba($color: #000000, $alpha: .5);
        border: 1px solid #eff1f3 !important;

        &.vip {
            background: rgba($color: #443D09, $alpha: .5);
        }
    }
}

.player-rewards {
    margin: 0px;
    padding: 10px 0 0 0;
    background: rgba($color: #000000, $alpha: .5);
    border-radius: 0 0 20px 20px;
    padding-bottom: 5px;
    .store-item {
        text-align: center;
        padding: 0 10px 0 10px;
        background: rgba($color: #000000, $alpha: .5);
        border-radius: 5px;
        border: 1px solid #eff1f3 !important;
        margin-bottom: 5px;

        .aws-btn {
            margin-top: 7px;
        }
        b {
            vertical-align: -webkit-baseline-middle;
        }

        &.vip {
            background: rgba($color: #443D09, $alpha: .5);
        }

        &.claimmed {
            background: rgba($color: darkgray, $alpha: .5);
            color: rgb(29, 29, 29);
            filter: grayscale(1);
        }

        .item-icon {
            width: 60px;
        }
    }
}

.RSPBprogressBar {
    margin-top: 25px;
}